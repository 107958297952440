<template>
  <b-container fluid>
    <!-- Search Form -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.budget_type') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('research_manage.budget_type')"
              label-for="budget_type"
            >
              <b-form-input
                id="budget_type"
                v-model="search.budget_type"
                placeholder=""
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <div class="text-right">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <!-- /Search Form -->
    <b-row>
      <b-col md="12">
        <iq-card>
          <!-- headerTitle -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.budget_type') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <!-- headerAction -->
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <!-- body -->
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                    <!-- Table -->
                  <b-table bordered hover :items="listData" :fields="columns"  aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <!-- Active/Inactive -->
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-danger" v-if="data.item.status === 2">{{$t('globalTrans.inactive')}}</span>
                        <span class="badge badge-success" v-else>{{$t('globalTrans.active')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- Modal 1 -->
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" title="Edit" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <!-- Change Status | Toggle Status-->
                      <b-button :variant="data.item.status === 2 ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" title="Active/Deactive" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status === 2 ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>

import Form from './Form'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { budgetTypeListApi, budgetTypeToggleStatusApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        budget_head_code: '',
        budget_head: ''
      },
      editItemId: 0
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('research_manage.budget_type') + ' ' + this.$t('globalTrans.entry')
        : this.$t('research_manage.budget_type') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('research_manage.budget_type'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'budget_type_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'budget_type_en' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
  },
  mounted () {
    this.loadData()
  },
  methods: {
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, budgetTypeToggleStatusApi, item, 'agri_research', 'budgetTypeList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, budgetTypeListApi, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
