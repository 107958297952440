<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="formLoader">
                    <b-row>
                       <b-col xl="12" lg="12" sm="12">
                          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                              <ValidationProvider name="Budget Type (En)" vid="budget_type_en" rules="required|max:100">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_type_en"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.budget_type_en') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="budget_type_en"
                                    v-model="budgetHead.budget_type_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>

                              <ValidationProvider name="Budget Type (Bn)" vid="budget_type_bn" rules="required|max:100">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="budget_type_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.budget_type_bn') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    id="budget_type_bn"
                                    v-model="budgetHead.budget_type_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                  </b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            <div class="row">
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                          </b-form>
                          </ValidationObserver>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { budgetTypeStoreApi, budgetTypeUpdateApi } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBudgetHeadData()
      this.budgetHead = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      formLoader: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      budgetHead: {
        budget_type_en: '',
        budget_type_bn: ''
      }
    }
  },
  computed: {
  },
  methods: {
    getBudgetHeadData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

      let result = null

      if (this.budgetHead.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${budgetTypeUpdateApi}/${this.id}`, this.budgetHead)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, budgetTypeStoreApi, this.budgetHead)
      }

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })

        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })

      this.formLoader = false
    }
  }
}
</script>
