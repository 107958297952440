export const designationWiseUser = '/master-project-setup/officer-list'
// Project Starts
export const projectInfoList = '/master-project-info/list'
export const projectInfoStore = '/master-project-info/store'
export const projectInfoUpdate = '/master-project-info/update'
export const projectInfoToggleStatus = '/master-project-info/toggle-status'
export const projectInfoDestroy = '/master-project-info/destroy'

// Project Setup
export const projectSetupList = '/master-project-setup/list'
export const projectSetupStore = '/master-project-setup/store'
export const projectSetupUpdate = '/master-project-setup/update'
export const projectSetupToggleStatus = '/master-project-setup/toggle-status'
export const projectSetupDestroy = '/master-project-setup/destroy'
export const circularList = '/master-project-setup/circular-list'
export const projectList = '/master-project-setup/project-list'

// Project Sub Setup
export const projectSetupSubList = '/master-project-setup-sub/list'
export const projectSetupSubStore = '/master-project-setup-sub/store'
export const projectSetupSubUpdate = '/master-project-setup-sub/update'

// Project Starts
export const programInfoList = '/master-program-info/list'
export const programInfoStore = '/master-program-info/store'
export const programInfoUpdate = '/master-program-info/update'
export const programInfoToggleStatus = '/master-program-info/toggle-status'
export const programInfoDestroy = '/master-program-info/destroy'

// Sub Project Starts
export const subProjectInfoList = '/master-sub-project-info/list'
export const subProjectInfoStore = '/master-sub-project-info/store'
export const subProjectInfoUpdate = '/master-sub-project-info/update'
export const subProjectInfoToggleStatus = '/master-sub-project-info/toggle-status'
export const subProjectInfoDestroy = '/master-sub-project-info/destroy'

// Sector Starts
export const sectorInfoList = '/master-sector-info/list'
export const sectorInfoStore = '/master-sector-info/store'
export const sectorInfoUpdate = '/master-sector-info/update'
export const sectorInfoToggleStatus = '/master-sector-info/toggle-status'
export const sectorInfoDestroy = '/master-sector-info/destroy'

// Research Program
export const ResearchProgramInfoList = '/master-research-program/list'
export const ResearchProgramInfoStore = '/master-research-program/store'
export const ResearchProgramInfoUpdate = '/master-research-program/update'
export const ResearchProgramInfoToggleStatus = '/master-research-program/toggle-status'
export const ResearchProgramInfoDestroy = '/master-research-program/destroy'

// Committee type Setup
export const CommitteeTypeSetupInfoList = '/committee-type-setup/list'
export const commiteeListFetch = '/committee-user-setup/committeelist'
export const CommitteeTypeSetupInfoStore = '/committee-type-setup/store'
export const CommitteeTypeSetupInfoUpdate = '/committee-type-setup/update'
export const CommitteeTypeSetupInfoToggleStatus = '/committee-type-setup/toggle-status'
export const CommitteeTypeSetupInfoDestroy = '/committee-type-setup/destroy'

// Committee Setup
export const CommitteeSetupInfoList = '/committee-setup/list'
export const RoleList = '/committee-setup/rolelist'
export const CommitteeSetupInfoStore = '/committee-setup/store'
export const CommitteeSetupInfoUpdate = '/committee-setup/update'
export const CommitteeSetupInfoToggleStatus = '/committee-setup/toggle-status'
export const CommitteeSetupInfoDestroy = '/committee-setup/destroy'

// Committee User Setup
export const CommitteeUserSetupInfoList = '/committee-user-setup/list'
export const memberList = '/committee-user-setup/memberlist'
export const CommitteeUserSetupInfoStore = '/committee-user-setup/store'
export const CommitteeUserSetupInfoUpdate = '/committee-user-setup/update'
export const CommitteeUserSetupInfoToggleStatus = '/committee-user-setup/toggle-status'
export const CommitteeUserSetupInfoDestroy = '/committee-user-setup/destroy'

// Subject Expert
export const subjectExpertInfoList = '/subject-expert/list'
export const subjectExpertInfoStore = '/subject-expert/store'
export const subjectExpertInfoUpdate = '/subject-expert/update'
export const subjectExpertInfoToggleStatus = '/subject-expert/toggle-status'
export const subjectExpertInfoDestroy = '/subject-expert/destroy'

// Sub Sector Starts
export const subSectorInfoList = '/master-sub-sector-info/list'
export const subSectorInfoStore = '/master-sub-sector-info/store'
export const subSectorInfoUpdate = '/master-sub-sector-info/update'
export const subSectorInfoToggleStatus = '/master-sub-sector-info/toggle-status'
export const subSectorInfoDestroy = '/master-sub-sector-info/destroy'

// Division Starts
export const researchDivisionList = '/master-research-division/list'
export const researchDivisionStore = '/master-research-division/store'
export const researchDivisionUpdate = '/master-research-division/update'
export const researchDivisionToggleStatus = '/master-research-division/toggle-status'
export const researchDivisionDestroy = '/master-research-division/destroy'

// researchThematicArea Starts
const researchThematicArea = '/national-research-config'
export const researchThematicAreaList = researchThematicArea + '/thematic-area/list'
export const researchThematicAreaStore = researchThematicArea + '/thematic-area/store'
export const researchThematicAreaUpdate = researchThematicArea + '/thematic-area/update'
export const researchThematicAreaToggleStatus = researchThematicArea + '/thematic-area/toggle-status'
export const researchThematicAreaDestroy = researchThematicArea + '/thematic-area/destroy'

export const researchDivisionThematicAreaList = researchThematicArea + '/division-thematic-area/list'
export const researchDivisionThematicAreaStore = researchThematicArea + '/division-thematic-area/store'
export const researchDivisionThematicAreaUpdate = researchThematicArea + '/division-thematic-area/update'
export const researchDivisionThematicAreaToggleStatus = researchThematicArea + '/division-thematic-area/toggle-status'
export const researchDivisionThematicAreaDestroy = researchThematicArea + '/division-thematic-area/destroy'

// Component Organization Starts
export const componentOrganizationList = researchThematicArea + '/component-organization/list'
export const componentOrganizationStore = researchThematicArea + '/component-organization/store'
export const componentOrganizationUpdate = researchThematicArea + '/component-organization/update'
export const componentOrganizationToggleStatus = researchThematicArea + '/component-organization/toggle-status'
export const usersByDesignationApi = '/user/list-by-designation'

// Proposal Invitation
export const proposalInvitationList = '/national-research/proposal-invitation/list'
export const proposalInvitationListCustom = '/national-research/proposal-invitation/customlist'
export const proposalInvitationStore = '/national-research/proposal-invitation/store'
export const proposalInvitationUpdate = '/national-research/proposal-invitation/update'
export const proposalInvitationToggleStatus = '/national-research/proposal-invitation/toggle-status'
export const proposalInvitationDestroy = '/national-research/proposal-invitation/destroy'

// Budget Type
const budgetTypeBase = 'national-research-config/budget-type'
export const budgetTypeListApi = budgetTypeBase + '/list'
export const budgetTypeStoreApi = budgetTypeBase + '/store'
export const budgetTypeUpdateApi = budgetTypeBase + '/update'
export const budgetTypeToggleStatusApi = budgetTypeBase + '/toggle-status'

// Budget Head
const budgetHeadBase = 'national-research-config/budget-head'
export const budgetHeadListApi = budgetHeadBase + '/list'
export const budgetHeadStoreApi = budgetHeadBase + '/store'
export const budgetHeadUpdateApi = budgetHeadBase + '/update'
export const budgetHeadToggleStatusApi = budgetHeadBase + '/toggle-status'

// Proposal Technical Evaluation
export const proposalTechnicalEvaluationList = '/national-research/proposal-technical-evaluation/list'
export const proposalTechnicalEvaluationBiriList = '/national-research/proposal-technical-evaluation/brri-list'
export const proposalTechnicalEvaluationForward = '/national-research/proposal-technical-evaluation/forward'
export const proposalTechnicalEvaluationForwardList = '/national-research/proposal-technical-evaluation/brri-forward-list'
export const proposalTechnicalEvaluationNoteUpdate = '/national-research/proposal-technical-evaluation/update-note'
export const proposalTechnicalEvaluationRecommendNoteUpdate = '/national-research/proposal-technical-evaluation/update-note-recommend'
export const researchProposalDetailsApi = '/national-research-panel/proposal-submission/details'

// Divisional Proposal Approval
export const divProposalApprovalPiList = '/national-research/divisional-proposal-approval/pi-list'
export const divProposalApprovalRdList = '/national-research/divisional-proposal-approval/rd-list'
export const divProposalApprovalRcList = '/national-research/divisional-proposal-approval/rc-list'
export const divProposalApprovalOiList = '/national-research/divisional-proposal-approval/oi-list'
export const divProposalSendToRdNoteUpdate = '/national-research/divisional-proposal-approval/send-rd-note'
export const proposalReportList = '/national-research/divisional-proposal-report/proposal-report'
export const divProposalApprovalNoteUpdate = '/national-research/proposal-approval/update-note'

// Proposal Approval
export const proposalApprovalList = '/national-research/proposal-approval/list'
export const proposalApprovalBrriList = '/national-research/proposal-approval/brri-list'
export const proposalApprovalNoteUpdate = '/national-research/proposal-approval/update-note'

// research-project-report
export const researchProjectReportList = '/national-research/research-project-report/list'

// report-approval
export const getApprovedApplicationList = '/national-research/report-approval/list'
export const approvedApplicationReportStore = '/national-research/report-approval/store'
export const getProposalData = 'national-research/report-approval/show'
export const approvedOrReject = 'national-research/report-approval/approved-or-reject'

// PeriodicActivityMonitoring Approval
export const periodicActivityMonitoringList = '/national-research/periodic-activity-monitoring/list'
export const periodicActivityMonitoringShow = '/national-research/periodic-activity-monitoring/show'
export const activityRemarksUpdate = '/national-research/periodic-activity-monitoring/update-remarks'
export const activityProgressStore = '/national-research/periodic-activity-monitoring/store/progress'
export const activityProgressShow = '/national-research/periodic-activity-monitoring/show/progress'

// Fund request
export const fundRequestListApi = 'research-panel/fund-request/list'
export const fundRequestStatusChangeApi = 'research-panel/fund-request/change-status'
export const fundRequestDisburseListApi = 'research-panel/fund-request/list-of-disburse'
export const fundRequestPaymentDisburseApi = 'research-panel/fund-request/payment-disburse'

// Budget Ledger
export const budgetLedgerListApi = 'national-research/research-budget-ledger/list'

// Report
export const activityProgressReportApi = 'report/activity-report'
export const activityDetailsApi = 'report/activity-details'

export const projectListApi = 'research-panel/project-title-list'

// Content Area api
export const researchDashboardApi = '/national-research/dashboard/index'

export const fundAllocatedAmountApi = 'research-panel/fund-request/get-allocated-amount'

// role-information
const roleInformation = '/national-research-config'
export const roleInformationList = roleInformation + '/role-information/list'
export const roleInformationStore = roleInformation + '/role-information/store'
export const roleInformationUpdate = roleInformation + '/role-information/update'
export const roleInformationToggleStatus = roleInformation + '/role-information/toggle-status'
export const roleInformationDestroy = roleInformation + '/role-information/destroy'

// monitoring-team
// const monitoringTeam = '/NationalResearchMonitoring'
export const monitoringTeamList = 'national-research/monitoring-team/list'
export const monitoringTeamStore = 'national-research/monitoring-team/store'
export const monitoringTeamUpdate = 'national-research/monitoring-team/update'
export const monitoringTeamToggleStatus = 'national-research/monitoring-team/toggle-status'
export const monitoringTeamDestroy = 'national-research/monitoring-team/destroy'

// monitoring schedule
export const monitoringScheduleList = 'national-research/monitoring-schedule/list'
export const scheduleProjectListApi = 'national-research/monitoring-schedule/project-list'
export const monitoringScheduleStoreApi = 'national-research/monitoring-schedule/store'
export const monitoringScheduleShowApi = 'national-research/monitoring-schedule/show'

// Budget Head
const monitoringTeamMembersBase = 'national-research/monitoring-team-members'
export const monitoringTeamMembersListApi = monitoringTeamMembersBase + '/list'
export const monitoringTeamMembersShowApi = monitoringTeamMembersBase + '/show'
export const monitoringTeamMembersStoreApi = monitoringTeamMembersBase + '/store'
export const monitoringTeamMembersUpdateApi = monitoringTeamMembersBase + '/update'
export const monitoringTeamMembersToggleStatusApi = monitoringTeamMembersBase + '/toggle-status'

// Monitoring Team Projects
const monitoringTeamProjectsBase = 'national-research/monitoring-team-projects'
export const monitoringTeamProjectsList = monitoringTeamProjectsBase + '/list'
export const monitoringTeamProjectsShow = monitoringTeamProjectsBase + '/show'
export const monitoringTeamProjectsStore = monitoringTeamProjectsBase + '/store'
export const monitoringTeamProjectsUpdate = monitoringTeamProjectsBase + '/update'
// Monitoring Team Projects #Extras
export const monitoringTeamProjectsTeamWithMembers = monitoringTeamProjectsBase + '/team-members'
export const monitoringTeamProjectsApproveProjectList = monitoringTeamProjectsBase + '/approved-project-list'

// monitoring report
export const monitoringReportListApi = 'national-research/monitoring-report/list'
export const monitoringReportShowApi = 'national-research/monitoring-report/show'

// Nothi api
export const nothi = 'nothi/'
export const pendingNothiResearchApi = nothi + 'research-sent-nothi'
export const reSentNothiResearchApi = nothi + 'research-re-sent-nothi'
